import { Replay } from "@sentry/nextjs"

const sentryClientConfig = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  enableTracing: false,
  tracesSampleRate: 0.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  ignoreErrors: [
    "ERR_UNAUTHORIZED",
    "Unauthorized",
    "Dynamic server usage",
    "ResizeObserver loop completed with undelivered notifications",
    "ResizeObserver loop limit exceeded",
  ],
  environment: process.env.NEXT_PUBLIC_ENV,
  enabled: process.env.NEXT_PUBLIC_ENV !== "development",
}

export default sentryClientConfig
